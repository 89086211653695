#map {
    height: 250px;
}
th {
    padding: 5px 15px;
}

@media (min-width: 768px) {
    #map {
        height: 460px;
    }
}