p {
    margin-bottom: 1rem;
}
h5 {
    font-weight: 500;
    margin-top: 2.5rem;
    margin-bottom: 0.5rem;
    letter-spacing: 0;
}
.copy p {
    margin: 0 auto;
    padding-bottom: 1rem;
    max-width: 600px;
    font-size: 1.8rem;
}
.copy h5 {
    padding-top: 3rem;
    padding-bottom: 0.4rem;
    margin: 0 auto;    
    line-height: 1.2;
    max-width: 600px;
}
ul {
    margin-left: 3rem;
    font-size: 1.7rem;
}
hr {
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.u-no-decoration,
.u-no-decoration:hover {
    text-decoration: none !important;
    border: none !important;
}
.arrow-down {
  text-transform: uppercase;
  font-size: 13px;
  background: #d54545;
  border-radius: 11px;
  padding: 3px 6px;
  padding-left: 0;
  color: white;
  top: -3px;
  position: relative;
}
.arrow-down svg {
  top: 7px;
  left: 5px;
  position: relative;
  fill: white;
}
