/* Hero banner */
.hero {
    text-align: center;
}
.hero-headline,
.hero-copy {
    text-align: center;
    margin: 1rem;
}
.hero-copy {
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.2;
    margin-bottom: 2rem;
    color: $grey;
}
.hero-headline {
    font-family: $serif;
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: 0;
}
@media (min-width:600px) {
    .hero-headline {
        font-size: 6rem;
    }
}
.hero-headline a {
    text-decoration: none;
    color: $black;
}
.hero-img {
    box-shadow: 0 5px 10px $light_grey;
}
.pill {
    background-color: #fffaab;
    padding: 4px;
    border-radius: 5px;
    font-size: 12px;
    text-transform: uppercase;
    top: -1px;
    position: relative;
    margin-right: 3px;
}
@media (min-width: 600px) {
    .pill {
        margin-left: -83px;
    }
}