.box {
    margin: 0 auto;
    max-width: 600px;
}
.copy-title-main {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 1rem;
    font-weight: 500;
    font-size: 2.5rem;
    text-align: center;
    border-bottom: 1px solid $light_grey;
    margin-bottom: 1.5rem;
}
.copy-list {
    margin-top: 1rem;
    list-style: disc;
    line-height: 1.35;
    font-size: 1.8rem;
}
.call-to-action {
    font-weight: 400;
    max-width: 380px;
    margin: 0 auto;
    text-align: center;
    line-height: 1.2;
    font-size: 1.9rem;
    padding-bottom: 2rem;
}
.section-title {
    margin: 0 auto;
    text-align: center;
    font-size: 3rem;
}
.post-img {
    max-width: 280px;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3)
}
.post-img-m {
    margin-bottom: 2rem;
}
.post-img-wrapper {
    padding-top: 2rem;
    padding-bottom: 2rem;
    text-align: center;
    margin: 0 auto;    
}
.table-distances {
    margin: 0 auto;
}
.landing-list {
    list-style: disc;
    padding-left: 2rem;
    margin: 0 auto;
    max-width: 600px;
}
.map-label {
    font-size: 1.4rem;
    float: right;
    line-height: 1.2;
    margin-top: 0.5rem;
    margin-bottom: 0;
    color: $grey
}
.btn {
    margin: 0 auto;
    text-align: center;
    background: $blue;
    color: white;
    border: none;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    font-size: 1.4rem;
    font-weight: 500;
}
a > .btn {
    transition: 0.2s;
}
a > .btn:hover {
    transform: scale(1.05);
    color: white;
}
a:visited > .btn {
    color: white;
}

@media (min-width: 768px) {
    .post-img {
        max-width: 320px;
    }    
    .post-img-m {
        margin-bottom: 0;
        margin-right: 2rem;
    }
    .landing-list {
        padding-left: 8rem;
    }
    .copy-list {
        margin-left: 5rem;
    }
}
