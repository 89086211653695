@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "skeleton/skeleton",
  "footer",
  "shared",
  "header",
  "fonts",
  "utils",
  "colors",
  "gallery",
  "landing",
  "location",
  "hero"
;

html {
  font-size: 62.5%;
}

body {
  font-family: $sans;
}
.lazyload,
.lazyloading {
  opacity: 0;
  transition: opacity .3s ease-in;
}
.lazyloaded {
  opacity: 1;
}