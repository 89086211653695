@import "fonts";

.footer {
    text-align: center;
    border-top: 1px solid $light_grey;
}
.footerText {
    font-family: $sans;
    font-weight: 400;
    margin-bottom: 0.5rem;
    line-height: 1.6rem;
}
