.gallery {
    margin: 0 auto;
    text-align: center;
}
.gallery a {
    display: inline-block;
    text-decoration: none;
}
.gallery-img {
    width: 250px;
}

@media (min-width: 768px) {
    .gallery-img {
        width: 317px;
    }
}