@import "fonts";
@import "colors";

.nav {
    text-align: right;
    margin-bottom: 0.5rem;
}
.nav a {
    text-decoration: none;
    color: $black;
}
.nav a:hover {
    border-bottom: 1px solid $black;
}
.phone {
    display: inline-block;
    border-bottom: 1px solid $green;
}
.phone svg {
    fill: $green;
}
.phone span {
    vertical-align: super;
}
.email {
    display: inline-block;
    border-bottom: 1px solid $grey;
}
.email svg {
    margin-right: 0.2rem;
    fill: $grey;
}
.email span {
    vertical-align: text-bottom;
}
.nav-list {
    list-style: none;
    margin-bottom: 2rem;
}
.nav-list li {
    margin-right: 1rem;
    display: inline-block;
    font-size: 1.5rem;
}
.nav-list li:last-child {
    margin-right: 0;
}
.lang-picker {
    margin-right: 1rem;
}