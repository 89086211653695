.center {
    text-align: center;
}
.mb0 {
    margin-bottom: 0;
}
.mb1 {
    margin-bottom: 1rem;
}
.mb2 {
    margin-bottom: 2rem;
}
.mb3 {
    margin-bottom: 4rem;
}
.mb4 {
    margin-bottom: 4rem;
}
.mt0 {
    margin-top: 0;
}
.mt1 {
    margin-top: 1rem;
}
.mt2 {
    margin-top: 2rem;
}
.mt3 {
    margin-top: 4rem;
}
.mt4 {
    margin-top: 4rem;
}
.pb0 {
    padding-bottom: 0;
}
.pb1 {
    padding-bottom: 1rem;
}
.pb2 {
    padding-bottom: 2rem;
}
.pb3 {
    padding-bottom: 4rem;
}
.pb4 {
    padding-bottom: 4rem;
}
.pt0 {
    padding-top: 0;
}
.pt1 {
    padding-top: 1rem;
}
.pt2 {
    padding-top: 2rem;
}
.pt3 {
    padding-top: 4rem;
}
.pt4 {
    padding-top: 4rem;
}
.mr1 {
    margin-right: 1rem;
}
.mr2 {
    margin-right: 2rem;
}
.mr3 {
    margin-right: 3rem;
}
.mr4 {
    margin-right: 4rem;
}

@media (max-width: 600px) {
    .mb1-m {
            margin-bottom: 1rem !important;
        }
    .pb1-m {
        margin-bottom: 1rem !important;
    }
}